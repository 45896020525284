import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer className="py-5 bg-dark">
                <div className="container  text-white">
                    <p>
                        Kule savunma oyunları, genellikle oyuncuların belirli bir harita veya yol boyunca ilerleyen düşman dalgalarını durdurmak için kuleler inşa etmelerini ve bunları yükseltmelerini gerektiren bir tür strateji oyunudur. Oyuncular genellikle sınırlı bir kaynakla başlarlar ve kaynaklarını en iyi şekilde yöneterek düşman dalgalarını yenmeye çalışırlar.
                    </p>
                    <p>
                        Kule savunma oyunlarında genellikle farklı türde kuleler bulunur ve her biri belirli bir düşman tipine karşı daha etkilidir. Oyuncular, kuleleri stratejik olarak yerleştirir ve bazen bu kuleleri geliştirebilir veya yükseltebilirler. Düşmanlar her seviyede genellikle daha güçlü hale gelir, bu yüzden oyuncuların stratejilerini sürekli olarak ayarlamaları ve geliştirmeleri gerekir.
                    </p>
                    <p>
                        Bu tür oyunlar genellikle mobil cihazlar, bilgisayarlar veya oyun konsolları üzerinde bulunabilir. Popüler kule savunma oyunları arasında "Bloons Tower Defense", "Kingdom Rush", "Defense Grid", ve "Plants vs. Zombies" gibi oyunlar bulunmaktadır. Bu oyunlar genellikle strateji, hızlı düşünme ve kaynak yönetimi becerilerini geliştirmeye yardımcı olur.
                    </p>
                    <p className="m-0 text-center text-white">Copyright &copy; <a href="https://kulesavunma.com">KuleSavunma.com</a> 2024 <a href="https://kulesavunma.com">Kule Savunma Oyunları</a></p>
                </div>
            </footer>
        </div>
    )
}

export default Footer