import React from 'react'

const Header = () => {
    return (
        <div>
            <header className="bg-dark py-5">
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">Kule Savunma</h1>
                        <p className="lead fw-normal text-white-50 mb-0">En İyi Kule Savunma Oyunları'nın büyülü dünyasına adım atın. Düşman dalgalarına karşı kulelerinizi inşa edin ve stratejinizi geliştirin. Heyecan dolu bir maceraya hazır mısınız? Hadi başlayalım ve zaferi kazanalım!</p>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header