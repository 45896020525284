import React from 'react'
import './App.css';

import Nav from './Components/Nav'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Content from './Components/Content'


const App = () => {
  return (
    <div>
      <Nav />
      <Header />
      <Content />
      <Footer />
    </div>
  )
}

export default App