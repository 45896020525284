import React from 'react'

const Content = () => {
    return (
        <div>
            <section className="py-5">
                <div className="container px-4 px-lg-5 mt-5">
                    <div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">

                        <div className="col mb-5">
                            <div className="card h-100">
                                <div className="badge bg-dark text-white position-absolute" style={{ top: '0.5rem', right: '0.5rem' }}>Yeni</div>
                                <img className="card-img-top" src="./img/bloonstowerdefense.png" alt="Bloons Tower Defense" />
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">Bloons Tower Defense</h5>
                                        <div className="d-flex justify-content-center small text-warning mb-2">
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                        </div>
                                        <span className="text-muted">
                                            Ninja Kiwi tarafından geliştirilen bir kule savunma oyunu serisidir
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                    <div className="text-center">
                                        <a className="btn btn-warning mt-auto" href="https://kulesavunma.com/bloonstowerdefense">Hemen Oyna</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col mb-5">
                            <div className="card h-100">
                                <img className="card-img-top" src="./img/kingdomrush.png" alt="Kingdom Rush" />
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">Kingdom Rush</h5>
                                        <div className="d-flex justify-content-center small text-warning mb-2">
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                        </div>
                                        <span className="text-muted">
                                            Ironhide tarafından geliştirilen, Armor Games'in yayınladığı bir kule savunma oyunu
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                    <div className="text-center">
                                        <a className="btn btn-warning mt-auto" href="https://kulesavunma.com/kingdomrush">Hemen Oyna</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col mb-5">
                            <div className="card h-100">
                                <img className="card-img-top" src="./img/defensegrid.png" alt="Defense Grid: The Awakening" />
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">Defense Grid: The Awakening</h5>
                                        <div className="d-flex justify-content-center small text-warning mb-2">
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                        </div>
                                        <span className="text-muted">
                                            Hidden Path Entertainment ve Xbox Live Arcade tarafından geliştirilen kule savunma oyunudur
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                    <div className="text-center">
                                        <a className="btn btn-warning mt-auto" href="https://kulesavunma.com/defensegrid">Hemen Oyna</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col mb-5">
                            <div className="card h-100">
                                <img className="card-img-top" src="./img/plantsvszombies.png" alt="Plants vs. Zombies" />
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">Plants vs. Zombies</h5>
                                        <div className="d-flex justify-content-center small text-warning mb-2">
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                        </div>
                                        <span className="text-muted">
                                            Windows ve Android cihazlara PopCap Games tarafından yapılmış bir kule savunma oyunudur
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                    <div className="text-center">
                                        <a className="btn btn-warning mt-auto" href="https://kulesavunma.com/plantsvszombies">Hemen Oyna</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col mb-5">
                            <div className="card h-100">
                                <img className="card-img-top" src="./img/gemcraft.png" alt="GemCraft" />
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">GemCraft</h5>
                                        <div className="d-flex justify-content-center small text-warning mb-2">
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                        </div>
                                        <span className="text-muted">
                                            Sihirli taşların saldırı ve savunma aracı olarak kullanıldığı kule savunma oyunudur
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                    <div className="text-center">
                                        <a className="btn btn-warning mt-auto" href="https://kulesavunma.com/gemcraft">Hemen Oyna</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col mb-5">
                            <div className="card h-100">
                                <img className="card-img-top" src="./img/dungeondefenders.png" alt="Dungeon Defenders" />
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">Dungeon Defenders</h5>
                                        <div className="d-flex justify-content-center small text-warning mb-2">
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                        </div>
                                        <span className="text-muted">
                                            Trendy tarafından geliştirilen, kule savunma ve rpg türlerini birleştiren çok oyunculu bir oyundur
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                    <div className="text-center">
                                        <a className="btn btn-warning mt-auto" href="https://kulesavunma.com/dungeondefenders">Hemen Oyna</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col mb-5">
                            <div className="card h-100">
                                <img className="card-img-top" src="./img/orcsmustdie.png" alt="Orcs Must Die!" />
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">Orcs Must Die!</h5>
                                        <div className="d-flex justify-content-center small text-warning mb-2">
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                        </div>
                                        <span className="text-muted">
                                            Robot Entertainment ve Mastertronic tarafından yayınlanan bir aksiyon-kule savunma oyunudur
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                    <div className="text-center">
                                        <a className="btn btn-warning mt-auto" href="https://kulesavunma.com/orcsmustdie">Hemen Oyna</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col mb-5">
                            <div className="card h-100">
                                <img className="card-img-top" src="./img/primeworlddefenders.png" alt="Prime World: Defenders" />
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">Prime World: Defenders</h5>
                                        <div className="d-flex justify-content-center small text-warning mb-2">
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                            <div className="bi-star-fill"></div>
                                        </div>
                                        <span className="text-muted">
                                            Prime World evreninde geçen platformlar arası bir kart Kule Savunma oyunudur
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                    <div className="text-center">
                                        <a className="btn btn-warning mt-auto" href="https://kulesavunma.com/primeworlddefenders">Hemen Oyna</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Content