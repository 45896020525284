import React from 'react'

const Nav = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container px-4 px-lg-5">
                    <a className="navbar-brand" href="https://kulesavunma.com">Kule Savunma Oyunları</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
                            <li className="nav-item"><a className="nav-link active" aria-current="page" href="https://kulesavunma.com/oyna">Kule Savunma Oyunu</a></li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Kule Savunma Oyunları</a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="https://kulesavunma.com/en_iyi_kulesavunma_oyunu.html">En İyi Kule Savunma Oyunu</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="https://kulesavunma.com/mobil_kulesavunma_oyunu.html">Mobil Kule Savunma Oyunu</a></li>
                                    <li><a className="dropdown-item" href="https://kulesavunma.com/bedava_kulesavunma_oyunu.html">Bedava Kule Savunma Oyunu</a></li>
                                    <li><a className="dropdown-item" href="https://kulesavunma.com/yeni_kule_savunma_oyunlari.html">Yeni Kule Savunma Oyunları</a></li>
                                </ul>
                            </li>
                        </ul>
                        <form className="d-flex">
                            <a className="btn btn-success" href="https://kulesavunma.com/kule_savunma_oyunu_oyna.html">
                                <i className="bi-play me-1"></i>
                                Kule Savunma Oyna
                            </a>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Nav